<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived {{ isProposal ? "Proposals" : "Tours" }}</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            :label="
              isProposal ? 'Search Archived Proposals' : 'Search Archived Tours'
            "
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              light
              :to="{
                name: isProposal
                  ? 'module-drum-proposals'
                  : 'module-drum-tours',
              }"
            >
              Return to active list
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="proposals"
            no-data-text="There are no archived proposals"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    isProposal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [],
      tableHeaders: [
        { text: "Title", value: "name" },
        { text: "Date", value: "formatted_dates.date" },
        { text: "Lead Customer", value: "lead_customer.full_name" },
        {
          text: "Last Updated",
          value: "formatted_dates.updated_at",
          sortable: false,
        },
        { text: "Actions", value: "actions", align: "right", sortable: false },
      ],
    };
  },

  computed: {
    proposals() {
      let proposals = this.$store.state.drum.tours["archived"];

      if (this.searchTerm !== "") {
        proposals = proposals.filter(r => {
          const name = r.name.toLowerCase();
          return name.includes(this.searchTerm.toLowerCase());
        });
      }
      return proposals;
    },
  },

  created() {
    this.setBreadcrumbs();
  },

  methods: {
    setBreadcrumbs() {
      this.breadcrumbs = [];

      if (this.isProposal) {
        this.breadcrumbs.push({
          text: "Proposals",
          disabled: false,
          exact: true,
          to: {
            name: "module-drum-proposals",
            params: { id: this.$route.params.id },
          },
        });
      } else {
        this.breadcrumbs.push({
          text: "Tours",
          disabled: false,
          exact: true,
          to: {
            name: "module-drum-tours",
            params: { id: this.$route.params.id },
          },
        });
      }

      this.breadcrumbs.push({
        text: "Archived",
        disabled: true,
      });
    },
  },
};
</script>
